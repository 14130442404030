/**
 * Main project app script!
 *
 * All script functionality should be in this file \
 *
 * // TODO jquery most likely will have to be disabled in WP and bundled in the app.js. I have suspicions that binding $ doesn't work
 **/
import Swiper from "swiper/bundle";
import OverlayScrollbars from "./lib/overlayScrollbars";
import BetterMenu from "./modules/supers/lib/better-menu";

// Smooth scrolling
import smoothscroll from "smoothscroll-polyfill";
// kick off the polyfill!
smoothscroll.polyfill();
import "smoothscroll-anchor-polyfill";

// Jank way to make sure this file does not get treeshaked
const utilities = {};

utilities;
document.addEventListener("DOMContentLoaded", () => {
	const scrollbars = new OverlayScrollbars();
	scrollbars.run();

	//const betterMenu = new BetterMenu(1024);

	const swiper = new Swiper(".swiper-container", {
		// Optional parameters
		loop: false,
		allowTouchMove: false,

		// If we need pagination
		pagination: {
			el: ".swiper-pagination",
		},

		// Navigation arrows
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},

		breakpoints: {
			400: {
				slidesPerView: 1,
			},
			640: {
				slidesPerView: 2,
			},
			1024: {
				slidesPerView: 3,
			},
		},
	});
});
